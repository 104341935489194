/* eslint-disable max-len */
import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsPartial,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';

const KeyboardShortcut = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.KeyboardShortcut;
    return <Component {...props} />;
  },
});

import pageHeroData from '../../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Number List"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Modules"
          tierThree="Number List"
        />
        <PageNavigation
          links={[
            'Behavior',
            'View Modes',
            'Usage',
            'Microcopy',
            'Accessibility',
            'Related',
            'Platform Support',
          ]}
        />
        <Section title="Behavior">
          <SectionSubhead>Selection</SectionSubhead>
          <Paragraph>
            The number list allows single or multi selection when clicking or
            tapping on individual items. When selected, the entire item turns
            blue and filters data based on the selection.
          </Paragraph>
        </Section>

        <Section title="View Modes">
          <Paragraph>
            Number lists can be viewed in the <strong>preview</strong> and{' '}
            <strong>expanded</strong>{' '}
            <Link href="../../data-card/design#View%20Modes">
              view modes for data cards
            </Link>
            .
          </Paragraph>
          <SectionSubhead>Preview</SectionSubhead>
          <Paragraph>
            In the preview view mode, the number list should always display the
            first three to five items in the list. For any lists including six
            or more items, the first three to five items should always display
            and all others will remain hidden until the “View All” button is
            pressed to display the rest of the list.
          </Paragraph>
          <Paragraph>
            Any selected items within the list will always display in preview
            mode, whether or not the list has been expanded to show all items.
          </Paragraph>
          <SectionSubhead>Expanded</SectionSubhead>
          <Paragraph>
            When displayed in the expanded view mode, the number list always
            displays all items included in the list.
          </Paragraph>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Module System</SectionSubhead>
          <Paragraph>
            Number lists are part of the{' '}
            <Link href="/components/modules/design">module system</Link>, and
            should only be used within data cards. They should be used to filter
            content using multiple data set options.
          </Paragraph>
          <DontDo
            dontText="use number lists outside of data cards."
            doText="use number lists within the module system."
            imgFilename="number-list-module-system"
          />

          <SectionSubhead>Large Lists</SectionSubhead>
          <Paragraph>
            When a data set creates a large list of items, the amount of
            information may potentially be overwhelming. Limit the amount of
            data displayed by default.
          </Paragraph>
          <DontDo
            dontText="display a long list of many items by default."
            doText="display 3-5 items by default with the option to view the rest."
            imgFilename="number-list-large-lists"
          />

          <SectionSubhead>Grouping</SectionSubhead>
          <Paragraph>
            Use groups to divide larger lists into logical sections, like
            athlete roles or play types. Groups should always include items
            which are classed together and similar.
          </Paragraph>
          <DontDo
            dontText="separate lists into groups at random."
            doText="intentionally collect similar, related items from a list into a group."
            imgFilename="number-list-grouping-separate"
          />
          <DontDo
            dontText="give additional titles to groups to separate content."
            doText="visibly separate the groups from one another with dividers."
            imgFilename="number-list-grouping-titles"
          />
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Microcopy</SectionSubhead>
          <Paragraph>
            List item labels should be descriptive and conscise. In many cases,
            these labels will consist of names of people or play types or names.
            In instances when longer labels occur, truncation should happen
            appropriately so as not to converge the label and count.
          </Paragraph>
          <DontDo
            dontText="use punctuation to end a line."
            doText="display labels letter casing as the user has entered them."
            imgFilename="number-list-microcopy-punctuation"
          />
        </Section>

        <Section title="Accessibility">
          <Paragraph>
            By default, number and bar list visualizations use real list items (
            <code>&lt;li&gt;</code>) to render inside of a wrapper (
            <code>&lt;ul&gt;</code>
            ). These lists are related elements and are represented as such to
            assistive technology, like screen readers.
          </Paragraph>
          <SectionSubhead>
            Use descriptions when labels are partially hidden
          </SectionSubhead>
          <Paragraph>
            Sometimes the item label may be too long to display fully.
            Abbreviation or truncation may remove some of the information, so we
            must enable descriptions to provide the full context. We can provide
            that extra information via{' '}
            <Link
              href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA"
              isExternal>
              ARIA attributes
            </Link>{' '}
            for screen readers and tooltips.
          </Paragraph>
          <DontDo
            dontText="use tooltips to provide unnecessary information or directions."
            doText="use tooltips to display a concise description when labels could be misunderstood."
            imgFilename="number-list-accessibility-tooltips"
          />
          <SectionSubhead>Keyboard Navigation</SectionSubhead>
          <Paragraph>
            Tab navigation is fully supported by default. Users can press{' '}
            <KeyboardShortcut>enter</KeyboardShortcut> to toggle the selection
            of an item.
          </Paragraph>
          <AccessibilityAuditComponentResultsPartial componentName="Modules / Number List" />
        </Section>

        <Section title="Related">
          <h3 className="uni-margin--one--bottom">
            <Link href="../../bar-list/design">Bar List</Link>
          </h3>
          <h3 className="uni-margin--one--bottom">
            <Link href="../../data-card/design">Data Card</Link>
          </h3>
          <h3 className="uni-margin--one--bottom">
            <Link href="../../design#Choosing%20a%20Visualization">
              Data Visualizations
            </Link>
          </h3>
          <h3 className="uni-margin--one--bottom">
            <Link href="../../design">Module System</Link>
          </h3>
          <h3>
            <Link href="../../../../guidelines/accessibility/design">
              Accessibility
            </Link>
          </h3>
        </Section>

        <Section title="Platform Support">
          <Paragraph>
            The number list is available for React and React Native.
          </Paragraph>
        </Section>
      </PageWithSubNav>
    );
  }
}
